import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function AuthCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    // Ensure navigation happens after React Router is ready
    setTimeout(() => {
      const postLoginRedirect = localStorage.getItem("postLoginRedirect");
      if (postLoginRedirect) {
        console.log("Redirecting post login...")
        navigate(postLoginRedirect, { replace: true });
        //localStorage.removeItem("postLoginRedirect"); 
      } else {
        navigate("/", { replace: true }); // Default route
      }
    }, 1000); // Small delay to ensure React Router is ready
  }, []);

  return <div>Loading...</div>;
}

export default AuthCallback;