import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { useAuth0 } from "@auth0/auth0-react";

const DisplayForm = () => {
  const { formId } = useParams(); // Get formId from path
  const location = useLocation();

  const [survey] = useState(new Model());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [completedOn, setCompletedOn] = useState("");
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  let token;

  const { getAccessTokenSilently, isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  // ✅ Ensure Auth0 session is checked before redirecting
  useEffect(() => {
    const checkAuth = async () => {
      if (isLoading) return; // Wait until Auth0 finishes loading

      try {
        token = await getAccessTokenSilently();
        setIsCheckingAuth(false); // User is authenticated, proceed to load form
      } catch (error) {
        console.log("No valid session, redirecting to login...");
        localStorage.setItem("postLoginRedirect", location.pathname + location.search);

        loginWithRedirect({
          authorizationParams: {
            redirect_uri: `${window.location.origin}/auth-callback`,
          },
        });

      }
    };

    checkAuth();
  }, [isLoading, isAuthenticated]);

  // ✅ Fetch survey only after authentication is checked
  useEffect(() => {
    if (isCheckingAuth || !formId) return;

    const fetchForm = async () => {
      try {
        const formData = await fetch(
          `${import.meta.env.VITE_API_URL}/api/forms/${formId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        );
        const data = await formData.json();
        survey.fromJSON(data.formSchema);
        survey.data = data.content;

        if (data.content.status === "Completed") {
          survey.mode = "display";
          setCompletedOn(data.createdAt);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchForm();
  }, [formId, isCheckingAuth]);

  if (isLoading || isCheckingAuth) return <div>Authenticating...</div>;
  if (loading) return <div>Loading form...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Survey model={survey} />
      <div>
        <p>Completed On: {completedOn}</p>
      </div>
    </div>
  );
};

export default DisplayForm;