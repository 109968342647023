import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App.jsx';

import { Auth0Provider } from "@auth0/auth0-react";

const domain = import.meta.env.VITE_AUTH_DOMAIN
const clientId = import.meta.env.VITE_AUTH_CLIENT_ID

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/auth-callback`, // Fixed URL
      }}
      onRedirectCallback={() => {
        const postLoginRedirect = sessionStorage.getItem("postLoginRedirect");
        if (postLoginRedirect) {
          navigate(postLoginRedirect);
          sessionStorage.removeItem("postLoginRedirect");
        }
      }}
      useRefreshTokens={true} // Enables silent auth
      cacheLocation="localstorage" // Keeps session across tabs
    >
      <App />
    </Auth0Provider>
  </StrictMode>,
);
