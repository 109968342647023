import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { Model } from 'survey-core';
import surveyJson from '../models/mtbaldy2';
import { Survey } from 'survey-react-ui';
import { uploadFileToAzure } from '../services/blobStorage';
import { uploadFileToAzureFileService } from '../services/fileStorage';
import testData from '../assets/testform'
import theme from '../themes/mtbaldy'

const setPanels = (survey, maxMembers, minMembers) => {
  const dynamicPanel = survey.getQuestionByName("members");
  if (dynamicPanel) {
    dynamicPanel.maxPanelCount = maxMembers;
    dynamicPanel.minPanelCount = minMembers;
    console.log("Max Panel Count updated:", dynamicPanel.maxPanelCount);
    console.log("Min Panel Count updated:", dynamicPanel.minPanelCount);
  }
}

function formatDateToInput(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

const setFields = (survey) => {
  console.log("Setting fields to not required")
  survey.getAllQuestions().forEach((question) => {
    question.isRequired = false;
  });
}

const MySurvey = () => {
  const navigate = useNavigate();
  const { schemaId } = useParams(); // Get schemaId from path
  const location = useLocation(); // Get query params
  const queryParams = new URLSearchParams(location.search);
  const passGroup = queryParams.get('passGroup') || undefined;
  const groupId = queryParams.get('groupId') || undefined
  const dev = queryParams.get('dev') || "0";
  const [formName, setFormName] = useState('')

  const [survey] = useState(new Model());
  const [maxMembers, setMaxMembers] = useState(1)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const containerSasUrl = import.meta.env.VITE_GC_SA_SAS_TOKEN;

  if (!schemaId) {
    console.error("Missing schemaId in URL");
    alert("SchemaId is required")
    // navigate("/error"); // Handle missing schemaId
  }

  // Fetch schema data and configure survey
  useEffect(() => {
    const fetchSchema = async () => {
      try {
        //survey.applyTheme(theme)
        const schemaData = await fetch(`${import.meta.env.VITE_API_URL}/api/forms/schema/${schemaId}`)
        const json = await schemaData.json()
        survey.fromJSON(json.content)
        //console.log("Schema data: ", json)
        if (json.name) {
          setFormName(json.name)
        }

        if (dev == 1) {
          survey.data = testData
          setFields(survey)
        }
        survey.setValue("status", "New")

        //hack to support friends and family waiver and 6 members
        if (groupId == 'ff2526' || schemaId == '67cd3a538cdcef9a9b12e3cb'){
          console.log("F&F")
          console.log(survey.getQuestionByName('maxMembers').value)
          const surveyMaxMembers = survey.getQuestionByName('maxMembers').value
          setPanels(survey, surveyMaxMembers, 1)
        }

        //console.log("Schema Data Loaded:", schemaData);
      } catch (err) {
        console.log("Error fetching schema ", err)
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (schemaId) fetchSchema();
  }, [schemaId, survey]);

  // Fetch pass data and set survey values
  useEffect(() => {
    if (!survey) return;

    const fetchPass = async () => {
        try {
            const passData = await fetch(`${import.meta.env.VITE_API_URL}/api/waivers/${passGroup}`);
            const json = await passData.json();
            console.log("Pass Data Loaded:", json);

            if (json.formId) {
                alert("This waiver has been completed.");
                navigate(`/forms/${schemaId}/${json.formId}`);
                return;
            }

            if (json.passGroup) {
                survey.setValue("passGroup", json.passGroup);
            }

            if (json.adjustedMaxMembers) {
                setMaxMembers(json.adjustedMaxMembers);
                survey.setValue("maxMembers", json.adjustedMaxMembers);
                
                // Defer render until data is set
                survey.onAfterRenderSurvey.add(() => {
                    console.log("Survey fully rendered — setting panels...");
                    const dynamicPanel = survey.getQuestionByName("members");
                    if (dynamicPanel) {
                      dynamicPanel.maxPanelCount = json.adjustedMaxMembers;
                      dynamicPanel.minPanelCount = json.adjustedMaxMembers;
                      

                      console.log("Max Panel Count updated:", dynamicPanel.maxPanelCount);
                      console.log("Min Panel Count updated:", dynamicPanel.minPanelCount);
                      survey.render();
                    }
                    //setPanels(survey, json.adjustedMaxMembers, json.adjustedMaxMembers);
                });

                // Now trigger the render
                // survey.render();
            }
        } catch (err) {
            console.log("Error fetching pass data", err);
            setError(err.message);
        }
    };

    if (passGroup) fetchPass();
}, [passGroup]);


//   useEffect(() => {
//     const fetchPass = async () => {
//         try {
//             const passData = await fetch(`${import.meta.env.VITE_API_URL}/api/waivers/${passGroup}`);
//             const json = await passData.json();

//             console.log("Pass Data Loaded:", json);

//             if (json.formId) {
//                 survey.setValue("passGroup", json.passGroup || '');
//                 navigate(`/forms/${schemaId}/${json.formId}`);
//                 return;
//             }

//             if (json.adjustedMaxMembers) {
//                 const adjustedMaxMembers = json.adjustedMaxMembers;
//                 setMaxMembers(adjustedMaxMembers);
//                 survey.setValue("maxMembers", adjustedMaxMembers);
//                 setPanels(survey, adjustedMaxMembers, adjustedMaxMembers);
//             }

//         } catch (err) {
//             console.error("Error fetching pass data:", err.response ? await err.response.json() : err);
//             setError(err.message || "An unknown error occurred.");
//         }
//     };

//     if (passGroup) fetchPass();
// }, [passGroup, survey]); // Removed `maxMembers` from dependencies


  // Configure survey event handlers
  useEffect(() => {
    const sendResults = (sender, options) => {
      // console.log("Form data: ", sender.data)
      // console.log("formName: ", formName)
      //debugger
      const payload = {
        formSchemaId: schemaId,
        formSchema: sender.jsonObj,
        formContent: sender.data,
        formName: formName
      }
      console.log('Payload: ', payload)
      fetch(`${import.meta.env.VITE_API_URL}/api/forms`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
    };

    survey.onComplete.add(sendResults);

    return () => {
      survey.onComplete.remove(sendResults); // Clean up event handlers
    };
  }, [survey, formName]);

   // Configure survey event handlers
   useEffect(() => {
    const captureScreen = () => {
      console.log("....Upload screenshot here....")
    };

    survey.onComplete.add(captureScreen);

    return () => {
      survey.onComplete.remove(captureScreen); // Clean up event handlers
    };
  }, [survey]);
  

  //set/reset signature sign-on
  useEffect(() => {
    survey.onValueChanged.add((sender, options) => {
      
      if (options != undefined && options.question?.classMetaData.name != "signaturepad") {
        return
      }
      if (options.name.includes("-sd")) {
        // Check if signature field has been filled (i.e., it's not empty)
        if (options.value) {
          sender.setValue(`${options.name}-signed-on`, new Date().toISOString().slice(0, 16));
        } else {
          // If signature is cleared, you can optionally clear the signed-on date
          sender.setValue(`${options.name}-signed-on`, null); // Clear the signed-on date if signature is removed
        }
      }
    });    
  }, [])

  // Handle file upload
  useEffect(() => {
    const uploadHandler = async (sender, options) => {
      const [file] = options.files;
      try {
        debugger
        const uploadedUrl = await uploadFileToAzure(file, passGroup);
        //const uploadedUrl = await uploadFileToAzureFileService(file, '2425')
        
        options.callback(options.files.map((file)=> {
          return {
            file: file,
            content: uploadedUrl.split('?')[0]
          }
        })); // Pass the file URL back to SurveyJS
      } catch (error) {
        console.log("Error uploading file ", error)
      }
    };
  
    survey.onUploadFiles.add(uploadHandler);
  
    return () => {
      survey.onUploadFiles.remove(uploadHandler);
    };
  }, []);

  // Handle groupId
  useEffect(() => {
    const addGroupId = async (sender, options) => {
      try {
        if (groupId) {
          console.log('groupId: ', groupId)
          //survey.setVariable('groupId', groupId);
          survey.setValue("groupId", groupId);
        }
      } catch (err) {
        console.log("Error setting groupId", err)
        setError(err.message);
      }
    }
    
    survey.onAfterRenderSurvey.add(addGroupId);
  
    return () => {
      survey.onAfterRenderSurvey.remove(addGroupId);
    };
  }, [groupId, survey]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Survey model={survey} />
    </div>
  );
};

export default MySurvey;
